import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/adsweb/login',
    name: 'login',
    component: () => import('@/views/auth/auth'),
	},
	{
    path: '/adsweb/ads',
    name: 'ads',
    component: () => import('@/views/ads/ads'),
  },
];

export default routes;
